/*
* @Author: abhi
* @Date:   2024-10-10 17:45:44
* @Last Modified by:   abhishek714
* @Last Modified time: 2024-10-11 14:03:56
*/
import React, { useEffect } from 'react';
import Raphael from 'raphael';
import usMap from './us-map-svg'; // Adjust the import according to your file structure
import marker1 from "../../images/map-marker.png";
import marker2 from "../../images/map-marker-2.png";

const USMap = () => {
    useEffect(() => {
        const R = Raphael("us_map", 1000, 900);
        const attr = {
            "fill": "#d3d3d3",
            "stroke": "#fff",
            "stroke-opacity": "1",
            "stroke-linejoin": "round",
            "stroke-miterlimit": "4",
            "stroke-width": "0.75",
            "stroke-dasharray": "none"
        };

        // Draw Map
        for (const state in usMap) {
            const stateAttr = { ...attr }; // Spread operator to copy the attributes
            if (state === 'ok' || state === 'ky' || state === 'mt') {
                stateAttr.fill = '#fa4d09';
            } else if(state === 'or' || state === 'ca' || state === 'az' || state === 'tx' || state === 'il') {
                stateAttr.fill = '#fa4d09'
            }
            R.path(usMap[state]).attr(stateAttr);
        }

        // Coordinates for markers (x, y)
        const markers = {
            "Portland": { x: 85, y: 80 },
            "Fresno": { x: 80, y: 270 },
            "Atlanta": { x: 250, y: 100 },
            "Reno": { x: 95, y: 160 },
            "Phoenix": { x: 200, y: 380 },
            "Odessa": { x: 360, y: 410 },
            "Houston": { x: 520, y: 460 },
            "Dallas": { x: 480, y: 430 },
            "Oklahoma": { x: 460, y: 360 },
            "Itasca": { x: 615, y: 215 },
            "Louisville": { x: 660, y: 290 }
        };

        const markerImages = {
            "Portland": marker1,
            "Oklahoma": marker2,
            "Atlanta": marker2,
            "Louisville": marker2,
            "default": marker1
        };

        // Create clickable markers with an icon
        for (const city in markers) {
            const markerIcon = markerImages[city] || markerImages["default"];
            const marker = R.image(markerIcon, markers[city].x - 12, markers[city].y - 20, 24, 24);
            marker.attr({ cursor: 'pointer', title: city });
            // Add click event to the marker
            (function(city) {
                marker.click(function () {
                    const targetElement = document.querySelector(`.${city}`);
                    const previousSelected = document.querySelectorAll('.map_location');
                    previousSelected.forEach(element => {
                        element.style.border = ''; // Clear the border
                    });
                    if (targetElement) {
                        targetElement.style.border = '2px solid #fa4d09';
                        targetElement.scrollIntoView({ behavior: 'smooth' });
                    } else {
                        //alert(city + "City element not found.");
                    }
                    if (city === 'Oklahoma' || city === 'Atlanta' || city === 'Louisville') {
                        // Remove existing labels
                        const existingLabel = document.querySelector('.city-label');
                        if (existingLabel) {
                            existingLabel.remove();
                        }

                        // Create a new div for the city name
                        const usMapDiv = document.getElementById('us_map');

                        // Create a new div for the city name inside us_map div
                        const cityLabel = document.createElement('div');
                        cityLabel.className = 'city-label';
                        cityLabel.innerText = city;
                        cityLabel.style.position = 'absolute';
                        cityLabel.style.backgroundColor = '#125d67';
                        cityLabel.style.border = '1px solid #ccc';
                        cityLabel.style.padding = '5px';
                        cityLabel.style.borderRadius = '3px';
                        cityLabel.style.zIndex = '1000'; // Ensure it's on top

                        // Adjust position to be beside the marker
                        const markerX = markers[city].x;
                        const markerY = markers[city].y;
                        cityLabel.style.left = `${markerX + 30}px`; // Position to the right of the marker
                        cityLabel.style.top = `${markerY - 10}px`; // Position above the marker

                        usMapDiv.appendChild(cityLabel);
                    }
                });
            })(city);
        }
    }, []); // Empty dependency array to run once on mount

    return <div id="us_map" style={{ width: '100%', height: '550px', position: 'relative', textAlign: 'center' }}></div>;
};

export default USMap;
