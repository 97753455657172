/*
* @Author: Abhi Bhatt
* @Date:   2020-06-16 11:33:34
* @Last Modified by:   Abhi Bhatt
* @Last Modified time: 2020-07-10 20:02:53
*/
import React from 'react';
import {Link} from 'react-router-dom';
import DownloadService from '../../services/DownloadService/DownloadService';

class Downloads extends React.Component{
	constructor(props) {
		super(props);
		this.state = {
			download_data:[]
		};
		this.downloadService = new DownloadService();
		this.renderDownloadItems = this.renderDownloadItems.bind(this);
	}

	componentDidMount() {
		this.downloadService.getDownload().then(response=>{
			if(response.status===200) {
				let download_categories = response.data.result.download_categories;
				this.setState({download_data:download_categories})
			}
		});
	}

	renderDownloadItems() {
		let download_data = this.state.download_data;
		if(download_data && download_data.length>0){
			return Object(download_data).map(function(value,key){
				return(
					<React.Fragment key={key}>
						<div className="col-md-12">
							<h4>{value.downloads_category[0].fields.name}</h4>
								<div className="row">
									<div className="col-md-10 offset-md-1">
										<div className="download_items">
											<div className="row">
											{Object(value.download_items).map(function(value1,key1){
												return(
													<React.Fragment key={key+'-'+key1}>
														<div className="col-md-3 col-sm-3 col-lg-2 col-xl-2 col-6">
															<a href={value1.fields.pdf_document_file_name}><img src={value1.fields.image_file_name} alt="" className="d-block img-fluid mx-auto w-100"/></a>
														</div>
													</React.Fragment>
												)
											})}
											</div>
										</div>
									</div>	
								</div>
								<hr/>
						</div>
					</React.Fragment>
				)
			})
		}
	}

	render(){
		return(
			<React.Fragment>
				<main role="main" className="middle-content-area">
	<div className="container-fluid">
		<section className="mt-2">		
			<div className="row">
				<div className="col-md-12">
					<div className="stripDesign">
						<div className="strip1">
							<div className="formBox pt-2 pb-4 px-4">
								<h6>Find products by parameters:</h6>
								<form className="form-inline" action="">							
									<select className="form-control" id="sel1">
										<option>Motor group</option>
										<option>Cobra</option>
										<option>Constant Velocity Fan Kit</option>
										<option>Crusher Duty</option>
										<option>Farm Duty</option>
										<option>Metric IEC</option>
										<option>Oil Pump</option>
										<option>Phase Converter Motor</option>
										<option>PM DC</option>
										<option>Premium JM-JP</option>
										<option>Three Phase Aluminum</option>
										<option>Three Phase Cast Iron</option>
										<option>Three Phase Open Enclosure</option>
										<option>Washdown</option>
									</select>
									<select className="form-control" id="sel2">
										<option>Frame Size</option>
										<option>100L</option>
										<option>112M</option>
										<option>132M</option>
									</select>
									<select className="form-control" id="sel3">
										<option>Horsepower</option>
										<option>0.125</option>
										<option>0.16</option>
										<option>0.18</option>
										<option>0.25</option>
									</select>
									<select className="form-control" id="sel4">
										<option>Speed</option>
										<option>900</option>
										<option>1200</option>
										<option>1800</option>
										<option>3600</option>
									</select>	
									<select className="form-control" id="sel5">
										<option>Voltage</option>
										<option>115</option>
										<option>115/208-230</option>
										<option>12V</option>
										<option>180V</option>
									</select>	
									<button type="submit" className="btn btn-primary">Search</button>	
									<Link to="" className="clear-icon"><i className="far fa-times-circle" style={{fontSize:'1.2em'}}></i></Link>	
								</form>
							</div>	
						</div>
					</div>		
				</div>	
			</div>	
		</section>
		<section className="downloads-area mt-2">		
			<div className="row mb-4">
				{this.renderDownloadItems()}
			</div>
		</section>	
	</div>	
</main>
			</React.Fragment>
		)
	}

}

export default Downloads