/*
* @Author: Abhi Bhatt
* @Date:   2020-07-09 14:43:38
* @Last Modified by:   abhi
* @Last Modified time: 2021-05-04 16:52:48
*/
import React from 'react';
import {Redirect,Route} from 'react-router-dom';

class ProtectedRoute extends React.Component
{
	render() {
    const { component: Component, ...props } = this.props
    let session_id = localStorage.getItem('techtop_user_id');
    return (
      <Route 
        {...props} 
        render={props => (
          typeof session_id !== "undefined" && session_id !== null && session_id !== '' ?
            <Component {...props} /> :
            <Redirect to='/user/login/' />
        )} 
      />
    )
  }
}

export default ProtectedRoute;