import React from 'react';
import { Link } from 'react-router-dom';
import './TitleUnderline.style.css';

const TitleUnderline = ({ linkTo, title }) => {
  return (
    <h5 className="title-underline main-container font-weight-bold">
      <Link className="title-underline title pb-1" to={linkTo}>
        {title}
      </Link>
      <div className="title-underline three-lines">
        <div className="title-underline line line1"></div>
        <div className="title-underline line line2"></div>
        <div className="title-underline line line3"></div>
      </div>
    </h5>
  );
};

export default TitleUnderline;
