/*
* @Author: Abhi Bhatt
* @Date:   2020-06-16 14:52:24
* @Last Modified by:   Abhi Bhatt
* @Last Modified time: 2020-06-16 15:12:48
*/
import axios from 'axios';
import {API_URL} from '../../constants.js';

class NewsService{
	async getNews() {
		const url = `${API_URL}/get-news`;
		const headers = {
			'Content-Type':'multipart/form-data',
			'Access-Control-Allow-Origin':'*'

		}
		let formData = new FormData();
		return axios({
			url:url,
			method: 'get',
			crossDomain:true,
			data: formData,
			config:{headers:headers},
			//headers: {'Access-Control-Allow-Origin': '*'}
		}).then(function(response) {
			return response;
		}).catch(function(response) {
			return response;
		});
	}

}

export default NewsService