/*
* @Author: Abhi Bhatt
* @Date:   2020-06-16 14:45:13
* @Last Modified by:   Abhi Bhatt
* @Last Modified time: 2020-06-17 18:14:17
*/
import React from 'react';
import {Link} from 'react-router-dom';
import NewsService from '../../services/NewsService/NewsService';

const renderHTML = (rawHTML: string) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });

class News extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			news_data:[]
		}
		this.newsService = new NewsService();
		this.renderNewsItems = this.renderNewsItems.bind(this);
	}

	componentDidMount() {
		this.newsService.getNews().then(response=>{
			if(response.status===200) {
				this.setState({news_data:response.data.result.news});

			}
		});

	}

	renderNewsItems() {
		let news_data = this.state.news_data;
		if(news_data && news_data.length>0) {
			return(
				Object(news_data).map(function(value,key){
					return(
						<React.Fragment key={key}>
							<div className="row py-3">
								<div className="col-md-12 pt-5 pb-3">
									<div className="newsBox">
										{renderHTML(value.fields.text)}
										<h5>April 08, 2016 14:30 </h5>
									</div>
								</div>
							</div>
							<hr/>
						</React.Fragment>
					)

				})
			)
		}
	}

	render() {
		return(
			<React.Fragment>
				<main role="main" className="middle-content-area">
	<div className="container-fluid">
		<section className="mt-2">		
			<div className="row">
				<div className="col-md-12">
					<div className="stripDesign">
						<div className="strip1">
							<div className="formBox pt-2 pb-4 px-4">
								<h6>Find products by parameters:</h6>
								<form className="form-inline" action="">							
									<select className="form-control" id="sel1">
										<option>Motor group</option>
										<option>Cobra</option>
										<option>Constant Velocity Fan Kit</option>
										<option>Crusher Duty</option>
										<option>Farm Duty</option>
										<option>Metric IEC</option>
										<option>Oil Pump</option>
										<option>Phase Converter Motor</option>
										<option>PM DC</option>
										<option>Premium JM-JP</option>
										<option>Three Phase Aluminum</option>
										<option>Three Phase Cast Iron</option>
										<option>Three Phase Open Enclosure</option>
										<option>Washdown</option>
									</select>
									<select className="form-control" id="sel2">
										<option>Frame Size</option>
										<option>100L</option>
										<option>112M</option>
										<option>132M</option>
									</select>
									<select className="form-control" id="sel3">
										<option>Horsepower</option>
										<option>0.125</option>
										<option>0.16</option>
										<option>0.18</option>
										<option>0.25</option>
									</select>
									<select className="form-control" id="sel4">
										<option>Speed</option>
										<option>900</option>
										<option>1200</option>
										<option>1800</option>
										<option>3600</option>
									</select>	
									<select className="form-control" id="sel5">
										<option>Voltage</option>
										<option>115</option>
										<option>115/208-230</option>
										<option>12V</option>
										<option>180V</option>
									</select>	
									<button type="submit" className="btn btn-primary">Search</button>	
									<Link to={'#'} className="clear-icon"><i className="far fa-times-circle" style={{fontSize:'1.2em'}}></i></Link>	
								</form>
							</div>	
						</div>
					</div>		
				</div>	
			</div>	
		</section>
		<section className="news-area mt-2">		
			
			<div className="row">
				<div className="col-md-12 pt-3 pb-5">
					{this.renderNewsItems()}
				</div>
			</div>		
		</section>	
	</div>	
</main>
			</React.Fragment>
		)
	}
}

export default News;