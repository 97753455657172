/*
* @Author: Abhi Bhatt
* @Date:   2020-06-24 13:20:48
* @Last Modified by:   Abhi Bhatt
* @Last Modified time: 2020-06-24 14:04:55
*/
import axios from 'axios';
import {API_URL} from '../../constants.js';

class StaticPagesService {
	async getStaticPage(permalink) {
		const url = `${API_URL}/get-static-page/${permalink}/`;
		const headers = {
			'Content-Type':'multipart/form-data',
			'Access-Control-Allow-Origin':'*'

		}
		let formData = new FormData();
		return axios({
			url:url,
			method: 'get',
			crossDomain:true,
			data: formData,
			config:{headers:headers},
			//headers: {'Access-Control-Allow-Origin': '*'}
		}).then(function(response) {
			return response;
		}).catch(function(response) {
			return response;
		});
	}
}

export default StaticPagesService;