/*
* @Author: Abhi Bhatt
* @Date:   2020-06-24 14:45:01
* @Last Modified by:   Abhi Bhatt
* @Last Modified time: 2020-07-21 10:31:32
*/
import React from 'react';
import {Link} from 'react-router-dom';
import SearchService from './../../services/SearchService/SearchService';

class SearchBar extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			groupArray:{},
			frameSizeArray:{},
			horsePowerArray:{},
			rpmArray:{},
			voltageArray:{},
			fields: {},
			searchString:'',
			group_value:'',
			frame_size_value:'',
			horsepower_60hz_value:'',
			rpm_value:'',
			voltage_value:''
		};
		this.searchService = new SearchService();
		this.renderGroupSelection = this.renderGroupSelection.bind(this);
		this.renderFrameSizeSelection = this.renderFrameSizeSelection.bind(this);
		this.renderHorsePowerSelection = this.renderHorsePowerSelection.bind(this);
		this.renderRpmSelection = this.renderRpmSelection.bind(this);
		this.renderVoltageSelection = this.renderVoltageSelection.bind(this);
		this.search = this.search.bind(this);


	}

	componentDidMount(){
		let fields = this.state.fields;
		this.searchService.searchBarValues().then(response=>{
			if(response.status===200){
				this.setState({groupArray:response.data.result.groupArray})
				this.setState({frameSizeArray:response.data.result.frameSizeArray})
				this.setState({horsePowerArray:response.data.result.horsePowerArray})
				this.setState({rpmArray:response.data.result.rpmArray})
				this.setState({voltageArray:response.data.result.voltageArray})
			}
		});
		const search = window.location.search;
		const params = new URLSearchParams(search);
		
		this.setState({group_value:params.get('group')});
		fields['group'] = params.get('group');
		
		this.setState({frame_size_value:params.get('frame_size')});
		fields['frame_size'] = params.get('frame_size');

		this.setState({horsepower_60hz_value:params.get('horsepower_60hz')});
		fields['horsepower_60hz'] = params.get('horsepower_60hz');

		this.setState({rpm_value:params.get('rpm')});
		fields['rpm'] = params.get('rpm');

		this.setState({voltage_value:params.get('voltage')});
		fields['voltage'] = params.get('voltage');

		this.setState({fields:fields});
	}

	

	renderGroupSelection() {
		let groupArray = this.state.groupArray;
		let group_value = this.state.group_value;
		
		if(groupArray && groupArray.length>0) {
			return Object(groupArray).map(function(value,key){
				if(value!=='' && value!==null) {
					if(group_value===value) {
						return (
						<React.Fragment key={key}>
							<option value={value} selected>{value}</option>
						</React.Fragment>
						)
					}
					else{	
					return (
						<React.Fragment key={key}>
							<option value={value}>{value}</option>
						</React.Fragment>
						)
					}
				}
			});
		} 
	}

	renderFrameSizeSelection() {
		let frameSizeArray = this.state.frameSizeArray;
		let frame_size_value = this.state.frame_size_value;
		if(frameSizeArray && frameSizeArray.length>0){
			return Object(frameSizeArray).map(function(value,key){
				if(value!=='' && value!==null) {
					if(frame_size_value===value) {
						return (
						<React.Fragment key={key}>
							<option value={value} selected>{value}</option>
						</React.Fragment>
						)
					}
					else{	
					return (
						<React.Fragment key={key}>
							<option value={value}>{value}</option>
						</React.Fragment>
						)
					}
				}
			});
		}
	}

	renderHorsePowerSelection() {
		let horsePowerArray = this.state.horsePowerArray;
		let horsepower_60hz_value = this.state.horsepower_60hz_value;
		//alert(horsepower_60hz_value);
		if(horsePowerArray && horsePowerArray.length>0){
			return Object(horsePowerArray).map(function(value,key){
				if(value!=='' && value!==null) {
					if(parseInt(horsepower_60hz_value)===value) {
						return (
						<React.Fragment key={key}>
							<option value={value} selected>{value}</option>
						</React.Fragment>
						)
					}
					else{	
					return (
						<React.Fragment key={key}>
							<option value={value}>{value}</option>
						</React.Fragment>
						)
					}
				}
			});
		}
	}

	renderRpmSelection() {
		let rpmArray = this.state.rpmArray;
		let rpm_value = this.state.rpm_value;
		if(rpmArray && rpmArray.length>0){
			return Object(rpmArray).map(function(value,key){
				if(value!=='' && value!==null) {
					if(parseInt(rpm_value)===value) {
						return (
						<React.Fragment key={key}>
							<option value={value} selected>{value}</option>
						</React.Fragment>
						)
					}
					else{	
					return (
						<React.Fragment key={key}>
							<option value={value}>{value}</option>
						</React.Fragment>
						)
					}
				}
			});
		}
	}

	renderVoltageSelection() {
		let voltageArray = this.state.voltageArray;
		let voltage_value = this.state.voltage_value;
		if(voltageArray && voltageArray.length>0){
			return Object(voltageArray).map(function(value,key){
				if(value!=='' && value!==null) {
					if(voltage_value===value){
						return (
						<React.Fragment key={key}>
							<option value={value} selected>{value}</option>
						</React.Fragment>
					)
					}
					else {
						return (
						<React.Fragment key={key}>
							<option value={value}>{value}</option>
						</React.Fragment>
					)
					}
					
				}
			});
		}
	}

	handleChange(field, e){     
       // e.preventDefault();    
        let fields = this.state.fields;
        if(field.indexOf('data') !== -1)
        {
        	var newField = field.split(".");
        	//console.log(newField[0]);
        	if(newField[0] in fields)
        	{
	            if(newField[1] in fields[newField[0]])
	            {
	                if(newField[2] in fields[newField[0]][newField[1]])
	                {
	                    fields[newField[0]][newField[1]][newField[2]] = e.target.value;
	                    fields[field] = e.target.value;
	                }
	                else
	                {
	                    fields[newField[0]][newField[1]][newField[2]] = '';
	                    fields[newField[0]][newField[1]][newField[2]] = e.target.value;
	                    fields[field] = e.target.value;
	                }
	            }   
	            else
	            {
	                fields[newField[0]][newField[1]] = {};  
	                fields[newField[0]][newField[1]][newField[2]] = '';
	                fields[newField[0]][newField[1]][newField[2]] = e.target.value;
	                fields[field] = e.target.value;
	            }
	        }
	        else {
	            fields[newField[0]] = {};
	            fields[newField[0]][newField[1]] = {};
	            fields[newField[0]][newField[1]][newField[2]] = '';
	            fields[newField[0]][newField[1]][newField[2]] = e.target.value;
	            fields[field] = e.target.value;
	        }
	    }
	    else
	    {
	    	fields[field] = e.target.value;
	    }
	    this.setState({fields});
	}

	search(e) {
		e.preventDefault();
		let fields = this.state.fields;
		var searchString = '';
		//if(fields.keys.length>0){
			Object.keys(fields).map(function(value,key){
				searchString+= value+'='+fields[value]+'&';
			});
		//}
		this.setState({searchString:searchString});
		window.location.href = '/search-motor/?'+searchString;
		//console.log(searchString);
		//alert('asf');
	}

	render() {
		//console.log(this.state.group_value);
		return(
			<React.Fragment>
				<section className="mt-2">		
			<div className="row">
				<div className="col-md-12">
					<div className="stripDesign">
						<div className="strip1">
							<div className="formBox pt-2 pb-4 px-4">
								<h6>Find products by parameters:</h6>
								<form className="form-inline" action="">							
									<select className="form-control" id="group" ref="group" onChange={this.handleChange.bind(this, "group")} defaultValue={this.state.fields["group"]}>
										<option value="">Motor group</option>
										{this.renderGroupSelection()}
									</select>
									<select className="form-control" id="frame_size" onChange={this.handleChange.bind(this, "frame_size")} defaultValue={this.state.fields["frame_size"]} >
										<option value="">Frame Size</option>
										{this.renderFrameSizeSelection()}
									</select>
									<select className="form-control" id="horsepower_60hz" onChange={this.handleChange.bind(this, "horsepower_60hz")} defaultValue={this.state.fields["horsepower_60hz"]}>
										<option value="">Horsepower</option>
										{this.renderHorsePowerSelection()}
									</select>
									<select className="form-control" id="rpm" onChange={this.handleChange.bind(this, "rpm")} defaultValue={this.state.fields["rpm"]}>
										<option value="">Speed</option>
										{this.renderRpmSelection()}
									</select>	
									<select className="form-control" id="voltage" onChange={this.handleChange.bind(this, "voltage")} defaultValue={this.state.fields["voltage"]}>
										<option value="">Voltage</option>
										{this.renderVoltageSelection()}
									</select>	
									<button type="button" className="btn btn-primary" onClick={this.search}>Search</button>	
									<a className="clear-icon"><i className="far fa-times-circle" style={{fontSize: '1.2em'}}></i></a>	
								</form>
							</div>	
						</div>
					</div>		
				</div>	
			</div>	
		</section>
			</React.Fragment>
		)
	}
}

export default SearchBar;
