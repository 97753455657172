/*
* @Author: Abhi Bhatt
* @Date:   2020-06-25 17:53:36
* @Last Modified by:   Abhi Bhatt
* @Last Modified time: 2020-07-15 15:57:18
*/
import React from 'react';
import {Link} from 'react-router-dom';
import CategoryService from './../../services/CategoryService/CategoryService';

class SideBar extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			category_data:{}
		};
		this.categoryService = new CategoryService();
		this.renderSidebarData = this.renderSidebarData.bind(this);
		//this.renderProductData = this.renderProductData.bind(this);

	}

	componentDidMount() {
		this.categoryService.getCategory().then(response=>{
			if(response.status===200) {
				this.setState({category_data:response.data.result.categories});
			}
		});
	}

	renderSidebarData() {
		
		let category_data = this.state.category_data;
		if(category_data!='' && category_data.length>0) {
			return Object(category_data).map(function(value,key){
				//console.log(value);
				return(
					<React.Fragment key={key}>
						<li className="nav-item">
							<Link className="nav-link" to={'/categories/'+value.parent_category[0].fields.permalink}>{value.parent_category[0].fields.name}</Link>
						</li>
						{(value.child_category.data!='' && value.child_category.data.length>0) ?
							Object(value.child_category.data).map(function(value1,key1){
								return(
									<React.Fragment key={key+'-'+key1}>
										<li className="nav-item ml-2 subsubroot_categories">
											<Link className="nav-link" to={"/categories/"+value1.fields.permalink}><i className="far fa-circle"></i> {value1.fields.name}</Link>
										
										{(value1.products && value1.products.length>0) ?
										<ul className="nav-second-level">
										{Object(value1.products).map(function(value2,key2){
											return (<React.Fragment key={key1+'-'+key2}><li className="nav-item">
												<Link className="nav-link" to={"/products/"+value2[0].fields.permalink}><i className="fas fa-circle"></i> {value2[0].fields.name}</Link>
											</li></React.Fragment>)
										})}
										</ul> : '' }
										</li>
									</React.Fragment>
								)
							}) : '' }
						
					</React.Fragment>
				);
				//console.log(value);
			});
		}
	}

	renderProductData(products_data) {
		console.log(products_data);
	}

	render() {
		return(
			<React.Fragment>
				<ul className="navbar-nav mr-auto sidenav">
					{this.renderSidebarData()}
				</ul>
			</React.Fragment>
		)
	}
}

export default SideBar;