/*
* @Author: Abhi Bhatt
* @Date:   2020-06-23 15:55:37
* @Last Modified by:   Abhi Bhatt
* @Last Modified time: 2020-07-15 13:02:41
*/
import axios from 'axios';
import {API_URL} from '../../constants.js';

class ProductService {
	async getProductDetail(permalink) {
		const url = `${API_URL}/get-product-details/${permalink}/`;
		const headers = {
			'Content-Type':'multipart/form-data',
			'Access-Control-Allow-Origin':'*'

		}
		let formData = new FormData();
		return axios({
			url:url,
			method: 'get',
			crossDomain:true,
			data: formData,
			config:{headers:headers},
			//headers: {'Access-Control-Allow-Origin': '*'}
		}).then(function(response) {
			return response;
		}).catch(function(response) {
			return response;
		});
	}

	async getProductInventory(permalink) {
		const url = `${API_URL}/get-product-inventory/${permalink}/`;
		const headers = {
			'Content-Type':'multipart/form-data',
			'Access-Control-Allow-Origin':'*'

		}
		let formData = new FormData();
		return axios({
			url:url,
			method: 'get',
			crossDomain:true,
			data: formData,
			config:{headers:headers},
			//headers: {'Access-Control-Allow-Origin': '*'}
		}).then(function(response) {
			return response;
		}).catch(function(response) {
			return response;
		});
	}
}

export default ProductService;