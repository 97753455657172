import React, { useState } from 'react';
import chevronSvg from '../../images/assets/right_arrow.svg';
import './Accordion.css'; // Importamos el CSS separado

const Accordion = ({ name, subItems, collapseId = '' }) => {
  const [isCollapsed, setIsCollapsed] = useState(true);

  const handleAccordionClick = (event) => {
    event.stopPropagation(); // avoid to close the products container when clicking on the accordion
    setIsCollapsed(!isCollapsed);
  };

  let component = null;

  if (collapseId === '') {
    component = <h5 className="h5">{name}</h5>;
  } else {
    component = (
      <div>
        <h5 className="accordion-title mt-3 mb-0">
          <button
            className="accordion-button"
            type="button"
            aria-expanded={!isCollapsed}
            aria-controls={collapseId}
            onClick={handleAccordionClick}
            style={{
              color: isCollapsed ? '#FFF' : '#FA4516',
              borderBottom: isCollapsed ? '1px solid #fff' : 'none',
            }}
          >
            <img
              src={chevronSvg}
              alt="Chevron"
              className="chevron-img"
              style={{
                transform: isCollapsed ? 'rotate(0deg)' : 'rotate(90deg)',
                transition: 'transform 0.3s',
              }}
            />
            {name}
          </button>
        </h5>
        {!isCollapsed && (
          <div id={collapseId}>
            <ul className="list-unstyled h3 text-nowrap">
              {subItems.map((subItem, index) => {
                return (
                  <li key={index} className="list-unstyled">
                    <a href={subItem.permalink}>{subItem.name}</a>
                  </li>
                );
              })}
            </ul>
          </div>
        )}
      </div>
    );
  }

  return component;
};

export default Accordion;
