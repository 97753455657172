/*
* @Author: abhishek714
* @Date:   2024-10-07 11:44:38
* @Last Modified by:   abhi
* @Last Modified time: 2024-10-15 01:42:12
*/
import React from 'react';
import {Link,Redirect} from 'react-router-dom';
import uploadImageIcon from '../../images/assets/image-plus.webp';
import $ from 'jquery';
import UserService from './../../services/UserService/UserService';
import { Validation } from '../../validation';
import { SITE_URL } from '../../constants.js';

class MyAccount extends React.Component{
	constructor(props){
		super(props);
		this.state ={
			fields: {},
            errors: {},
            success_redirect:0,
            user_id: '',
            first_name: '',
            last_name: '',
            company:'',
            company_logo:uploadImageIcon,
            company_logo_file: null,
            address:'',
            state:'',
            city:'',
            zip:'',
            phone:'',
            cell_phone:'',
            direct_phone:'',
            fax:'',
            is_login: 0,
            is_logout: 0,
            form_error_message:'',
            success_message:''
		}
		this.userService = new UserService();
	}

	componentDidMount() {
		if (localStorage.getItem('techtop_user_id') != '' && localStorage.getItem('techtop_user_id') != null) {
			this.setState({ user_id: localStorage.getItem('techtop_user_id') });
			this.setState({ first_name: localStorage.getItem('techtop_user_first_name')});
			this.setState({ last_name: localStorage.getItem('techtop_user_last_name')});
			this.setState({ company: localStorage.getItem('techtop_user_company')});
			this.setState({ company_logo: SITE_URL+'files/company_logo/'+localStorage.getItem('techtop_user_company_logo')});
			this.setState({ phone: localStorage.getItem('techtop_user_phone')});
			this.setState({ cell_phone: localStorage.getItem('techtop_user_cell_phone')});
			this.setState({ direct_phone: localStorage.getItem('techtop_user_direct_phone')});
			this.setState({ fax: localStorage.getItem('techtop_user_fax')});
			this.setState({ address: localStorage.getItem('techtop_user_address')});
			this.setState({ state: localStorage.getItem('techtop_user_state')});
			this.setState({ city: localStorage.getItem('techtop_user_city')});
			this.setState({ zip: localStorage.getItem('techtop_user_zip')});
			this.setState({ is_login: 1 });
		}
	}

	handleChange(field, e){
		let { fields } = this.state; 
		const { name, value } = e.target;
        const newFormData = {
            ...fields,
            [name]: value,
        };
        this.setState({fields : newFormData});
	}

	handleLogoChange(e) {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                this.setState({
                    company_logo: e.target.result, // Display the image preview
                    company_logo_file: file, // Store the file for upload
                });
            };
            reader.readAsDataURL(file); // Convert the image to base64 for preview
        }
    }

	renderProfileForm() {
		return (
			<React.Fragment>
				<div className="col-md-7">
					<div className="row">
						<div className="col-md-12">
							<div className="mb-3">
								<label htmlFor="address" className="form-label">Address</label>
								<input type="text" className="form-control" name="address" id="address" onChange={this.handleChange.bind(this, "address")} defaultValue={this.state["address"]}/>
							</div>
						</div>	
					</div>
					<div className="row">
						<div className="col-md-4">
							<div className="mb-3">
								<label htmlFor="city" className="form-label">City</label>
								<input type="text" className="form-control" name="city" id="city" onChange={this.handleChange.bind(this, "city")} defaultValue={this.state["city"]} />
							</div>
						</div>	
						<div className="col-md-4">
							<div className="mb-3">
								<label htmlFor="state" className="form-label">State</label>
								<input type="text" className="form-control" name="state" id="state" onChange={this.handleChange.bind(this, "state")} defaultValue={this.state["state"]}/>
							</div>
						</div>	
						<div className="col-md-4">
							<div className="mb-3">
								<label htmlFor="zip" className="form-label">Zip</label>
								<input type="text" className="form-control" name="zip" id="zip" onChange={this.handleChange.bind(this, "zip")} defaultValue={this.state["zip"]} />
							</div>
						</div>	
					</div>
					<div className="row">
						<div className="col-md-6">
							<div className="mb-3">
								<label htmlFor="phone-number" className="form-label">Phone Number</label>
								<input type="number" className="form-control" name="phone" id="phone" onChange={this.handleChange.bind(this, "phone")} defaultValue={this.state["phone"]} />
							</div>
						</div>	
						<div className="col-md-6">
							<div className="mb-3">
								<label htmlFor="cell-phone-number" className="form-label">Cell Phone Number</label>
								<input type="number" className="form-control" name="cell_phone" id="cell_phone" onChange={this.handleChange.bind(this, "cell_phone")} defaultValue={this.state["cell_phone"]} />
							</div>
						</div>
					</div>	
					<div className="row">
						<div className="col-md-6">
							<div className="mb-3">
								<label htmlFor="direct-phone-number" className="form-label">Direct Phone Number</label>
								<input type="number" className="form-control" name="direct_phone" id="direct_phone" onChange={this.handleChange.bind(this, "direct_phone")} defaultValue={this.state["direct_phone"]} />
							</div>
						</div>	
						<div className="col-md-6">
							<div className="mb-3">
								<label htmlFor="fax-phone-number" className="form-label">Fax Phone Number</label>
								<input type="number" className="form-control" name="fax" id="fax" onChange={this.handleChange.bind(this, "fax")} defaultValue={this.state["fax"]} />
							</div>
						</div>
					</div>	
					<div className="row">
						<div className="col-md-12">
							<div className="mb-3">
								<button className="btn save-btn">Save</button>
							</div>
						</div>
						
					</div>	
				</div>	
			</React.Fragment>
		)
	}

	renderCompanyLogo() {
		return(
			<React.Fragment>
				<div className="col-md-5">
					<div className="upload-div">
						<div className="upload-img">
							<img src={this.state.company_logo} width="100" alt={this.state.company} />	
						</div>
						<input type="file" id="company_logo" className="d-none" onChange={this.handleLogoChange.bind(this)} />
						<button type="button" className="btn upload-btn" onClick={() => document.getElementById('company_logo').click()}>Upload Logo</button>
					</div>	
				</div>
			</React.Fragment>
		)
	}

	handleSubmit(e) {
		e.preventDefault();
		let { fields, user_id, company_logo_file } = this.state
		if (this.state.company_logo_file) {
			fields['company_logo'] = company_logo_file;
		}
		fields['user_id'] = parseInt(user_id);
		let data = fields;
		this.userService.updateProfile(data).then(response=>{
			console.log(response);
			this.setState({form_error_message:''});
        	if(response.status===200) {
        		if(response.data.response==='fail') {
        			this.setState({form_error_message:response.data.message});
        		}
        		else{
        			localStorage.setItem('techtop_user_id', response.data.result.pk);
		            localStorage.setItem(
		              'techtop_user_first_name',
		              response.data.result.first_name
		            );
		            localStorage.setItem(
		              'techtop_user_last_name',
		              response.data.result.last_name
		            );
		            localStorage.setItem(
		              'techtop_user_company',
		              response.data.result.company
		            );
		            localStorage.setItem(
		              'techtop_user_company_logo',
		              response.data.result.company_logo
		            );
		            localStorage.setItem(
		              'techtop_user_address',
		              response.data.result.address
		            );
		            localStorage.setItem(
		              'techtop_user_state',
		              response.data.result.state
		            );
		            localStorage.setItem(
		              'techtop_user_city',
		              response.data.result.city
		            );
		            localStorage.setItem(
		              'techtop_user_zip',
		              response.data.result.zip
		            );
		            localStorage.setItem(
		              'techtop_user_phone',
		              response.data.result.phone
		            );
		            localStorage.setItem(
		              'techtop_user_cell_phone',
		              response.data.result.cell_phone
		            );
		            localStorage.setItem(
		              'techtop_user_direct_phone',
		              response.data.result.direct_phone
		            );
		            localStorage.setItem(
		              'techtop_user_fax',
		              response.data.result.fax
		            );
        			$('#success_message').css('display','block');
        			$('#success_message').html(response.data.message);
        			this.setState({success_message:response.data.message});
        			this.setState({success_redirect:1});
        			localStorage.setItem('show_message',1);
        			localStorage.setItem('success_message',response.data.message);
        			window.location.reload();
        		}
        	}
		});
		console.log(this.state.fields);
	}

	render() {
		return(
			<React.Fragment>
				<div className="middle-div">
					<section className="my-account-section">
						<div className="container">
							<div className="row">
								<div className="col-md-12">
									<div className="default-title">
										<div className="row">
											<div className="col-md-6">	
												<h1 className="m-0 color1">My Account</h1>
											</div>
											<div className="col-md-6">	
												<h2 className="m-0 color1 text-end">{this.state.company}</h2>
											</div>
										</div>
									</div>
								</div>	
							</div>
							<div className="row">
								<div className="col-md-12 col-sm-12 col-lg-10 offset-lg-1 col-xl-10 offset-xl-1">
									<div className="sub-default-title">
										<h5 className="mt-3">{this.state.company}</h5>
									</div>	
									<div className="row">
										<form onSubmit={this.handleSubmit.bind(this)}>
											<div className="row">
											{ this.renderProfileForm() }
											{ this.renderCompanyLogo() }
											</div>
										</form>
									</div>	
								</div>	
							</div>			
						</div>
					</section>
				</div>
			</React.Fragment>
		);
	}

}

export default MyAccount;