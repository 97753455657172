/*
* @Author: Abhi Bhatt
* @Date:   2020-07-13 18:44:07
* @Last Modified by:   abhi
* @Last Modified time: 2021-05-04 16:52:35
*/
import React from 'react';
import {Link,Redirect} from 'react-router-dom';
import SearchBar from '../../components/SearchBar/SearchBar';
import $ from 'jquery';
import UserService from './../../services/UserService/UserService';

class ForgotPassword extends React.Component{
	constructor(props) {
		super(props);
		this.state = {
			fields: {},
            errors: {},
            success_redirect:0
		}
		this.forgotPasswordForm = this.forgotPasswordForm.bind(this);
		this.userService = new UserService();
		this.successRedirect = this.successRedirect.bind(this);
	}

	componentDidMount() {

	}

	forgotPasswordForm(e) {
		e.preventDefault();
		let errors = {};
		if(this.handleValidation()) {
			this.userService.forgotPassword(this.state.fields).then(response=>{
				if(response.status===200) {
					if(response.data.response==='fail'){
						errors["email"] = response.data.message;
						
					}
					else{
						errors['email'] = '';
						$('#success_message').css('display','block');
            			$('#success_message').html(response.data.message);
						this.setState({success_redirect:1});
            			localStorage.setItem('show_message',1);
            			localStorage.setItem('success_message',response.data.message);
            			window.location.reload();
					}
					this.setState({errors: errors});
					//console.log(response.data);
				}
			});
		}

	}

	successRedirect() {
    	if(this.state.success_redirect===1) {
    		return <Redirect to="/" />
    	}
    }

	handleValidation() {
    	let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;
        if(!fields["email"]){
        	formIsValid = false;
        	errors["email"] = "Please enter email.";
        }
        this.setState({errors: errors});
       	return formIsValid;
    }

    handleChange(field, e){     
       // e.preventDefault();    
        let fields = this.state.fields;
        if(field.indexOf('data') !== -1)
        {
        	var newField = field.split(".");
        	//console.log(newField[0]);
        	if(newField[0] in fields)
        	{
	            if(newField[1] in fields[newField[0]])
	            {
	                if(newField[2] in fields[newField[0]][newField[1]])
	                {
	                    fields[newField[0]][newField[1]][newField[2]] = e.target.value;
	                    fields[field] = e.target.value;
	                }
	                else
	                {
	                    fields[newField[0]][newField[1]][newField[2]] = '';
	                    fields[newField[0]][newField[1]][newField[2]] = e.target.value;
	                    fields[field] = e.target.value;
	                }
	            }   
	            else
	            {
	                fields[newField[0]][newField[1]] = {};  
	                fields[newField[0]][newField[1]][newField[2]] = '';
	                fields[newField[0]][newField[1]][newField[2]] = e.target.value;
	                fields[field] = e.target.value;
	            }
	        }
	        else {
	            fields[newField[0]] = {};
	            fields[newField[0]][newField[1]] = {};
	            fields[newField[0]][newField[1]][newField[2]] = '';
	            fields[newField[0]][newField[1]][newField[2]] = e.target.value;
	            fields[field] = e.target.value;
	        }
	    }
	    else
	    {
	    	fields[field] = e.target.value;
	    }
	    this.setState({fields});
	}

	render() {
		return(
			<React.Fragment>
			{this.successRedirect()}
				<main role="main" className="middle-content-area">
					<div className="container-fluid">
					<SearchBar/>
					<section className="new-area my-3">		
						<div className="row"> 
							<div className="col-md-12">
								<h5 style={{fontSize:"1.2em"}} className="pl-5">Forgot your password?</h5>
								<div className="col-md-6 offset-md-3">
								<form onSubmit={this.forgotPasswordForm.bind(this)}>
									<div className="form-group">
										<input type="email" className="form-control" placeholder="E-mail" name="email" id="email" ref="email" onChange={this.handleChange.bind(this, "email")} defaultValue={this.state.fields["email"]}/>
										<span style={{color: "red"}}>{this.state.errors["email"]}</span>
									</div>
									<div className="form-group">
										<button type="submit" className="btn btn-primary">send my reset password instructions</button>
									</div>
									<div className="shared-links">
										<Link to={"/user/login"}>Sign in</Link>&nbsp;&nbsp;<Link to={"/user/login"}>Sign up</Link><br/>
										<Link to={"/user/confirmation"}>Didn't receive confirmation instructions?</Link>
									</div>		
								</form>
								</div>
							</div>
						</div>		
					</section>
					</div>	
				</main>
			</React.Fragment>
		)
	}
}

export default ForgotPassword;