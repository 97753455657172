/*
* @Author: Abhi Bhatt
* @Date:   2020-06-09 18:50:45
* @Last Modified by:   Abhi Bhatt
* @Last Modified time: 2020-06-11 16:30:55
*/

import React from 'react';
//import ReactDOM from 'react-dom';



class Footer extends React.Component{
	constructor(props) {
		super(props);

	}

	componentDidMount() {

	}

	render() {
		return (
			<React.Fragment>
				<footer>
					<div className="container-fluid">
						<div className="row">
							<div className="col-md-5 col-sm-12 col-xl-5 col-lg-5 col-12 pl-4">
								<p>2815 Colonnades Court</p>
								<p><small>Peachtree Corners, GA 30071</small></p>
							</div>	
							<div className="col-md-4 col-sm-12 col-xl-3 col-lg-3 col-12">
								<p><i className="fas fa-phone"></i> Tel.<a href="tel:6784365540">678.436.5540</a></p>
								<p><i className="fas fa-envelope"></i> <a href="mailto:info@techtopind.com">info@techtopind.com</a></p>
							</div>	
							<div className="col-md-3 col-sm-12 col-xl-4 col-lg-4 col-12 text-right pr-5">
								<p><small>&copy; Techtop Ind. 2020</small></p>
							</div>	
						</div>	
					</div>
				</footer>
			</React.Fragment>
		)
	}
}

export default Footer;