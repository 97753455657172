import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
//import 'owl.carousel/dist/assets/owl.carousel.min.css';
//import './css/owl.theme.default.min.css';
import './css/responsive-tables.css';
import App from './App';
import 'jquery/dist/jquery.js';

//import './js/custom.js';
//import 'popper.js/dist/umd/popper.min.js';
import 'bootstrap/dist/js/bootstrap.min.js';
//import 'owl.carousel/dist/owl.carousel.js';


import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';


ReactDOM.render(
	<BrowserRouter>
	<App />
	</BrowserRouter>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();