import React, { Component } from 'react';
import Header from './components/Header/Header';
import UserHeader from './components/Header/UserHeader';
import Main from './components/Main/Main';
import Footer from './components/Footer/Footer';
import { urlPath } from './constants.js'

class App extends Component {
    componentDidMount() {
        const loadStyles = async () => {
          console.log(urlPath);
            if (urlPath === 'user/my-account' || urlPath === 'user/order-history' || urlPath === 'user/open-orders') {
                await import('./css/user/custom.css');
                await import('./css/user/responsive.css');
            } else {
                await import('./css/custom.css');
                await import('./css/responsive.css');
            }
        };

        loadStyles();
    }

    render() {
        return (
            <div className="App">
                {(urlPath === 'user/my-account'  || urlPath === 'user/order-history' || urlPath === 'user/open-orders') ? <UserHeader /> : <Header />}
                <Main />
                <Footer />
            </div>
        );
    }
}

export default App;